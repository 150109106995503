/*
 * @Description: 购物车状态模块
 */
export default {
  state: {
    shoppingCart: []
    // shoppingCart结构
    /* 
    shoppingCart = {
      goods_id: '',   // 商品id
      goods_sn: '',   // 商品序列号
      name: '',       // 商品名称
      thumbnail: '',  // 商品图片
      price: '',      // 商品价格
      goods_num: '',  // 商品数量
      color: '',      // 商品限购数量
      size: '',       // 商品尺寸
      weight: '',     // 商品重量
      check: false    // 是否勾选
    } */
  },
  getters: {
    /**
     * 获取购物车状态
     * @param {*} state 
     * @returns 
     */
    getShoppingCart (state) {
      return state.shoppingCart;
    },
    /**
     * 购物车商品总数量
     * @param {*} state 
     * @returns 
     */
    getNum (state) {
      let totalNum = 0;
      for (let i = 0; i < state.shoppingCart.length; i++) {
        const temp = state.shoppingCart[i];
        totalNum += parseInt(temp.goods_num);
      }
      return totalNum;
    },
    /**
     * 判断是否全选
     * @param {*} state 
     * @returns 
     */
    getIsAllCheck (state) {
      let isAllCheck = true;
      for (let i = 0; i < state.shoppingCart.length; i++) {
        const temp = state.shoppingCart[i];
        if (!temp.check) {
          isAllCheck = false;
          return isAllCheck;
        }
      }
      return isAllCheck;
    },
    /**
     * 获取勾选的商品信息
     * 用于确认订单页面
     * @param {*} state 
     * @returns 
     */
    getCheckGoods (state) {
      let checkGoods = [];
      for (let i = 0; i < state.shoppingCart.length; i++) {
        const temp = state.shoppingCart[i];
        if (temp.check) {
          checkGoods.push(temp);
        }
      }
      return checkGoods;
    },
    /**
     * 获取购物车勾选的商品数量
     * @param {*} state 
     * @returns 
     */
    getCheckNum (state) {
      let totalNum = 0;
      for (let i = 0; i < state.shoppingCart.length; i++) {
        const temp = state.shoppingCart[i];
        if (temp.check) {
          totalNum += parseInt(temp.goods_num);
        }
      }
      return totalNum;
    },
    /**
     * 购物车勾选的商品总价格
     * @param {*} state 
     * @returns 
     */
    getTotalPrice (state) {
      let totalPrice = 0;
      for (let i = 0; i < state.shoppingCart.length; i++) {
        const temp = state.shoppingCart[i];
        if (temp.check) {
          totalPrice += temp.price * temp.goods_num;
        }
      }
      return totalPrice.toFixed(2);
    }
  },
  mutations: {
    /**
     * 设置购物车状态
     * @param {*} state 
     * @param {*} data 
     */
    setShoppingCart (state, data) {
      state.shoppingCart = data;
    },
    /**
     * 添加购物车，用于在商品详情页点击添加购物车,后台添加成功后，更新vuex状态
     * @param {*} state 
     * @param {*} data 
     */
    unshiftShoppingCart (state, data) {
      state.shoppingCart.unshift(data);
    },
    /**
     * 更新购物车,可更新商品数量和是否勾选
     * 用于购物车点击勾选及加减商品数量
     * @param {*} state 
     * @param {*} payload 
     * @returns 
     */
    updateShoppingCart (state, payload) {
      // 根据商品在购物车的数组的索引和属性更改
      state.shoppingCart[payload.key][payload.prop] = payload.val;
    },
    /**
     * 增加购物车商品数量
     * 用于在商品详情页点击添加购物车
     * @param {*} state 
     * @param {*} goods_sn 
     */
    addShoppingCartNum (state, goods_sn) {
      for (let i = 0; i < state.shoppingCart.length; i++) {
        const temp = state.shoppingCart[i];
        if (temp.goods_sn == goods_sn) {
          temp.goods_num++;
        }
      }
    },
    /**
     * 根据商品sn删除购物车商品
     * @param {*} state 
     * @param {*} goods_sn 
     */
    deleteShoppingCart (state, goods_sn) {
      for (let i = 0; i < state.shoppingCart.length; i++) {
        const temp = state.shoppingCart[i];
        if (temp.goods_sn == goods_sn) {
          state.shoppingCart.splice(i, 1);
        }
      }
    },
    /**
     * 点击全选按钮，更改每个商品的勾选状态
     * @param {*} state 
     * @param {*} data 
     */
    checkAll (state, data) {
      for (let i = 0; i < state.shoppingCart.length; i++) {
        state.shoppingCart[i].check = data;
      }
    }
  },
  actions: {
    setShoppingCart ({ commit }, data) {
      commit('setShoppingCart', data);
    },
    unshiftShoppingCart ({ commit }, data) {
      commit('unshiftShoppingCart', data);
    },
    updateShoppingCart ({ commit }, payload) {
      commit('updateShoppingCart', payload);
    },
    addShoppingCartNum ({ commit }, goods_sn) {
      commit('addShoppingCartNum', goods_sn);
    },
    deleteShoppingCart ({ commit }, goods_sn) {
      commit('deleteShoppingCart', goods_sn);
    },
    checkAll ({ commit }, data) {
      commit('checkAll', data);
    }
  }
}